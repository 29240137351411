import { useEffect, useRef, useState } from "react";
import Service_Api, { ResponseProps } from "../../services/Api";
import { Cron } from "../../services/object/Cron";
import { GridCallbackDetails, GridRenderCellParams, GridRowParams, GridValueFormatterParams, MuiEvent } from "@mui/x-data-grid";
import { Button, Chip, IconButton, Tab, Tabs } from "@mui/material";
import Notification, { NotificationProps } from "../../components/notification/Notification";
import Alert, { AlertProps } from "../../components/alert/Alert";
import TableChart from "../../components/tables/Tablechart";
import ModalFormComponent from "../../components/modal/ModalFormComponent";
import { Server } from "../../services/object/Server";
import cronPopupChildrens from './CronPopupChildrens';
import { displayFormErrors } from "../../services/tools/errorForm";
import { useNavigate, useSearchParams } from "react-router-dom";
import { jsDateToLocalFr, sqlToJsDate } from "../../services/tools/translateDate";
import AddIcon from '@mui/icons-material/Add';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import DeleteIcon from '@mui/icons-material/Delete';
import PauseIcon from '@mui/icons-material/Pause';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { ADP, CALL, Category, DIVERS, STAT } from "../../services/object/Category";
import { useUser } from "../../services/auth/useUser";

function Accueil() {
  const [dataRow, setDataRow] = useState<{cron :Cron, server: Server}[]>();
  const [max, setMax] = useState<number>(0);
  const [cron, setCron] = useState<Cron>();
  const [modalOpen, setModalOpen] = useState(false);
  const [displayAlert, setDisplayAlert] = useState<AlertProps>();
  const [displayNotif, setDisplayNotif] = useState<NotificationProps>();
  const [errorMessages, setErrorMessages] = useState<Record<string, string>>({});
  const [popupChildren, setPopupChildren] = useState<JSX.Element[]>();
  const [TOServer, setTOServer] = useState<Server[]>([]);
  const [TOCategory, setTOCategory] = useState<Category[]>([]);
  
  const [loading, setLoading] = useState(false);

  const formRefCron = useRef<HTMLFormElement>() as React.RefObject<HTMLFormElement>;

  const { user } = useUser();

  const Api = Service_Api();

  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, value: number) => {
      if(value !== 0)
          navigate("/graphique");

  };

    useEffect(() => {
        fecthData()
    }, []);

  const fecthData = async() => {
    //get all cron (for row data)
    let response = await Api.get("server/");
    if(response?.success)
        setTOServer(response.data); 
    
    response = await Api.get("category/");
    if(response?.success)
        setTOCategory(response.data);
  }
  
  const getRowData = async (paginationModel: { page: number; pageSize: number;}, sort: {}, filter: {}, newState? : number) => {
    // fetch data from server
    setLoading(true)
    const response = await Api.post("cron/getAllCronForDataGrid", {
        page: paginationModel.page,
        pageSize: paginationModel.pageSize,
        sort,
        filter,
      });
    if(response?.success){
        setDataRow(response?.data);
        setMax(response?.recordsTotal ?? 0);
        setLoading(false)
    }else{
        setDataRow([]);
        setMax(0);
        setLoading(false)
    }
  };
    /* end hydrate data */
    /* table */
    //Create column
    const columns = [
        { field: "JRA_cron.name", headerName: 'Sujet', flex:1},
        { field: "JRA_cron.categoryUid",headerName: 'Label', type: 'string', flex:1,
                valueFormatter: (params: GridValueFormatterParams<any>) => params.value,
            renderCell: (params: GridRenderCellParams<any>) => {
                switch (params.row['JRA_cron.categoryUid']) {
                    case CALL:
                        return (<Chip label={"Call Center"} color="error" variant="outlined"/>)
                    case ADP:
                        return (<Chip label={"ADP"} color="success" variant="outlined"/>)
                    case STAT:
                        return (<Chip label={"Statistique"} color="warning" variant="outlined"/>)
                    case DIVERS:
                        return (<Chip label={"Traitement annexe"} color="info" variant="outlined"/>)
                    default:
                        return (<Chip label={"Traitement annexe"} color="info" variant="outlined"/>)
                }
            },
        },
        { field: "JRA_server.name", headerName: 'Serveur', flex:1,},
        { field: "JRA_cron.createdAt", headerName: 'Création',
            valueFormatter: (params: GridValueFormatterParams<any>) => { 
                if(params.value){
                    return jsDateToLocalFr(sqlToJsDate(params.value));
                }else{
                    return params.value;
                }
            },
            renderCell: (params: GridRenderCellParams<any>) => {
                if(params.value){
                    return jsDateToLocalFr(sqlToJsDate(params.value));
                }else{
                    return params.value;
                }
                
            }
        },
        { field: "JRA_cron.modifiedAt", headerName: 'Dernière modification',
            valueFormatter: (params: GridValueFormatterParams<any>) => { 
                if(params.value){
                    return jsDateToLocalFr(sqlToJsDate(params.value));
                }else{
                    return params.value;
                }
            },
            renderCell: (params: GridRenderCellParams<any>) => {
                if(params.value){
                    return jsDateToLocalFr(sqlToJsDate(params.value));
                }else{
                    return params.value;
                }
            }
        },
        { field: "JRA_cron.executedAt", headerName: 'Dernière exécution (+ durée)', flex:1,
            valueFormatter: (params: GridValueFormatterParams<any>) => { 
                if(params.value){
                    return jsDateToLocalFr(sqlToJsDate(params.value, true), true);
                }else{
                    return params.value;
                }
            },
            renderCell: (params: GridRenderCellParams<any>) => {
                if(params.value){
                    return jsDateToLocalFr(sqlToJsDate(params.value, true), true);
                }else{
                    return params.value;
                }
            }
        },
        { field: "JRA_cron.time", headerName: 'Durée (sec)'},
        { field: "JRA_cron.success",headerName: 'Statut', type: 'string',
            valueFormatter: (params: GridValueFormatterParams<any>) => params.value,
            renderCell: (params: GridRenderCellParams<any>) => {
                switch (params.row['JRA_cron.success']) {
                    case 0:
                        return (<Chip label={"Erreur"} color="error" variant="outlined"/>)
                    case 1:
                        return (<Chip label={"Succès"} color="success" variant="outlined"/>)
                    case 2:
                        return (<Chip label={"En pause"} color="info" variant="outlined"/>)
                    default:
                        return (<Chip label={"En Attente"} color="warning" variant="outlined"/>)
                }
            },
        },
        { field: 'action', headerName: 'Action', type: 'boolean', filterable: false, sortable: false, disableExport: true, 
            renderCell: (params: GridRenderCellParams<any>) => (
            <>
                {
                        (
                            <>
                                <IconButton title={"Lancer le cron"}  onClick={(e) => runCron(e, params)}>
                                    <RocketLaunchIcon/>
                                </IconButton>
                                {
                                    (params.row['JRA_cron.success'] !== 2) ?
                                        <IconButton title={"Mettre en pause"} onClick={(e) => stopLine(e, params)}>
                                            <PauseIcon/>
                                        </IconButton>
                                    :
                                        <IconButton title={"Réactiver"} onClick={(e) => stopLine(e, params)}>
                                            <PlayCircleIcon/>
                                        </IconButton>
                                }
                                <IconButton title={"Supprimer"} onClick={(e) => deleteLine(e, params)}>
                                    <DeleteIcon/>
                                </IconButton>
                            </>
                        )
                }
            </>),
        },
    ];


    //reset children data when cron change
    useEffect(() => {
        let children = cronPopupChildrens({errorMessages, 
                                            formRefCron, 
                                            cron, 
                                            TOServer,
                                            TOCategory,
                                            handleFormCronChange});
        setPopupChildren(children)
    }, [cron, TOServer, , TOCategory, errorMessages]);
  
    //event click on row
    const onRowClick = (params: GridRowParams, event: MuiEvent, details: GridCallbackDetails) => {
        let cronUid = params.id as string
        getCron(cronUid)
    }
    //event click on card
    const onCardClick = (cronUid:string) => {
        getCron(cronUid)
    }

  //event click on icon delete
  const deleteLine = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, params: GridRenderCellParams) =>{
    //desable onRowClick event
    event.preventDefault();
    event.stopPropagation();
    setDisplayAlert({
        open : true,
        title : "Suppression",
        contentText : "Êtes-vous sûr de vouloir supprimer ce cron ?",
        contentButtonSuccess : "Oui",
        contentButtonAbord : "Non",
        onSubmitSuccess:  async () => {
            //get current row id (= current cron id)
            let cronUid = params.id as string
            let response = await Api.del("cron/", {uid : cronUid});
            //reload table data
            let newData = dataRow?.filter(data => data.cron.uid !== cronUid)
            setDataRow(newData)
            if(response?.success){
                setDisplayNotif({
                    open : true,
                    contentText : "Le cron a été supprimé",
                    severity : "success",
                    handleClose:  ()=>(setDisplayNotif(undefined))
                })
            }else{
                setDisplayNotif({
                    open : true,
                    contentText : "Aïe, une erreur inconnue est apparue. Merci de réessayer plus tard. (code erreur: "+response?.messages.code+")",
                    severity : "error",
                    handleClose:  ()=>(setDisplayNotif(undefined))
                })
            }
            setDisplayAlert(undefined)
        },
        onSubmitAbord:  () => (setDisplayAlert(undefined))
    })
    
}
    const runCron = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, params: GridRenderCellParams) =>{
        event.preventDefault();
        event.stopPropagation();
        let cronUid = params.id as string
        let response = await Api.get("cron/execute", "?uid="+cronUid);
        if(response?.success){
            setDisplayNotif({
                open : true,
                contentText : "Le cron a bien été exécuté",
                severity : "success",
                handleClose:  ()=>(setDisplayNotif(undefined))
            })
        }else{
            setDisplayNotif({
                open : true,
                contentText : "Aïe, le cron a rencontré une erreur. (erreur: "+response?.data+")",
                severity : "error",
                handleClose:  ()=>(setDisplayNotif(undefined))
            })
        }
    }

  //event click on icon delete
  const stopLine = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, params: GridRenderCellParams) =>{
    let stateCron = params.row['JRA_cron.success'];
    //desable onRowClick event
    event.preventDefault();
    event.stopPropagation();
    setDisplayAlert({
        open : true,
        title : "Stop",
        contentText : (stateCron !== 2) ? "Êtes-vous sûr de vouloir mettre en pause ce cron ?" : "Êtes-vous sûr de vouloir réactiver ce cron ?",
        contentButtonSuccess : "Oui",
        contentButtonAbord : "Non",
        onSubmitSuccess:  async () => {
            //get current row id (= current cron id)
            let cronUid = params.id as string
            let response = await Api.put("cron/", {uid : cronUid, success:(stateCron !== 2) ? 2 : 3});
            //reload table data
            if(response?.success){
                setDisplayNotif({
                    open : true,
                    contentText : "Le cron a été mis en pause",
                    severity : "success",
                    handleClose:  ()=>(setDisplayNotif(undefined))
                })
            }else{
                setDisplayNotif({
                    open : true,
                    contentText : "Aïe, une erreur inconnue est apparue. Merci de réessayer plus tard. (code erreur: "+response?.messages.code+")",
                    severity : "error",
                    handleClose:  ()=>(setDisplayNotif(undefined))
                })
            }
            setDisplayAlert(undefined)
        },
        onSubmitAbord:  () => (setDisplayAlert(undefined))
    })
    
}
  const getCron = async(cronUid:string) => {
        //get cron by uid
        let response = await Api.get("cron/", "uid="+cronUid);
        //set current cron (to hydrate popup's input)
        setCron(response?.data);
        
        setModalOpen(true);
    }
  
    //event on click button create
    const handleOpenModal = () => {
        setModalOpen(true);
        setCron((cronValue : any) => ({
            ...cronValue,
            ['userUid']: user.uid,
        }));
        setDisplayAlert(undefined);
        //reset input's data
    };

    const [searchParams, setSearchParams] = useSearchParams();
    
    //event on click icon close
    const handleCloseModal = () => {
        setModalOpen(false);
        //reset input's data
        resetPopup();
        if (searchParams.has('cronUid')) {
            searchParams.delete('cronUid');
            setSearchParams(searchParams);
          }
    };

    const resetPopup = () => {
        setCron(undefined)
    }

    const onFormSubmitSuccess = async () => {
        let error = displayFormErrors(formRefCron);
        if(Object.keys(error).length === 0){
            //if current cron has uid it exist so put
            let response : ResponseProps|undefined;

            if(cron?.uid){
                let newCron = cron;
                response = await Api.put("cron/", newCron);
                let oldData = dataRow;
                if(oldData){
                    const index = oldData.findIndex(data => data.cron.uid === cron.uid);
                    // oldData = oldData.filter(data => data.cron.uid !== cron.uid)
                    oldData[index].cron = cron;
                    setDataRow(oldData);
                }                
            //else create it
            }else{
                response = await Api.post("cron/", cron);
                //reload table data
                let newData = dataRow;
                newData?.unshift(response?.data)
                setDataRow(newData);
                //set new current cron
                setCron(response?.data.cron)
            }
            handleCloseModal();
            if(response?.success)
                setDisplayNotif({
                    open : true,
                    contentText : "Cron enregistré",
                    severity : "success",
                    handleClose:  ()=>(setDisplayNotif(undefined))
                })  
            else{
                setDisplayNotif({
                    open : true,
                    contentText : "Une erreur inconnu est survenue",
                    severity : "error",
                    handleClose:  ()=>(setDisplayNotif(undefined))
                })  
                return false;
            }
            return true;
        }else{
            setErrorMessages(error);
            return false;
        }
    }

    const handleFormCronChange = (property: string, value: string| FileList | number | boolean | null) => {
        setCron((cronValue : any) => ({
            ...cronValue,
            [property]: value,
        }));
    }
  return (
    <div className="background">
      <div className="backsquare">
      <h1 className='title2'>Liste des crons actif</h1>
        <div className="tableContainer">
            <Button  className="addbutton" 
                     variant="contained" 
                     sx={{width:260, height:50}} 
                     onClick={handleOpenModal} 
                     endIcon={<AddIcon/>}> 
                Créer un cron
            </Button>
            <Tabs className="tabsContainer" value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab className="selected" label="Tableau"/>
                <Tab label="Graphique" />
            </Tabs>
            <TableChart 
                //onTableChange event do this func
                callBackFunctionGetRowData={getRowData} 
                //send new data to table and map to match structure of datagrid row
                dataRow={dataRow?.map(
                    (data:{cron :Cron, server: Server}) => {
                            return  { 
                            "id" : data.cron.uid,
                            "JRA_cron.name" : data.cron.name,
                            "JRA_cron.categoryUid" : data.cron.categoryUid,
                            "JRA_server.name" : data.server.name,
                            "JRA_cron.createdAt" : data.cron.createdAt,
                            "JRA_cron.modifiedAt" : data.cron.modifiedAt,
                            "JRA_cron.executedAt" : data.cron.executedAt,
                            "JRA_cron.time" : data.cron.time,
                            "JRA_cron.success" : data.cron.success,
                        }
                    }
                )}
                columns={columns} 
                columnVisibility = {{
                    "JRA_cron.createdAt": false,
                    "JRA_cron.modifiedAt" : false,
                  }}
                onRowClick={onRowClick}
                onCardClick={onCardClick}
                handleDeleteRow={(e)=>(true)}
                loading={loading}
                max={max}
                />
            <ModalFormComponent
                title={"Le cron"}
                modalOpen={modalOpen} 
                onClose={handleCloseModal} 
                onFormSubmitSuccess={onFormSubmitSuccess} 
                childrenForm={popupChildren}
                formEvent={[onFormSubmitSuccess]}
                buttonText={"Fermer"} 
                buttonIcon={null}/>

            {
                (displayAlert?.open === true) ? <Alert {...displayAlert}/>: ""
            }
            {
                (displayNotif?.open === true) ? <Notification {...displayNotif}/>: ""
            }
        </div>
      </div>
    </div>
  );
};

export default Accueil;